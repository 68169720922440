import React from "react";
import styles from "./Card.module.css";
import { locationIcon } from "../../Images/banner/images";
import { Link } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";

const Card = ({
  img,
  state,
  route,
  bookingsOpen,
  name,
  location,
  gmapsLink,
}) => {
  return (
    <div className={styles.card}>
      <Link to={`singleproject${route}`} style={{ cursor: "pointer" }}>
        <img src={img} className={styles.Image} />
      </Link>
      {bookingsOpen ? (
        <div className={styles.Tag} style={{ top: "40px", left: "-70px" }}>
          BOOKING OPEN
        </div>
      ) : (
        state === "completed" && (
          <div style={{ backgroundColor: "darkred" }} className={styles.Tag}>
            SOLD OUT
          </div>
        )
      )}
      <div className={styles.cardDetails}>
        <div className={styles.blueTxt}>{name}</div>
        <div className={styles.cardDetailsBottom}>
          <a className={styles.locationIcon} href={gmapsLink} target="_blank">
            <img src={locationIcon} />

            <span>{location}</span>
          </a>
          <Link className={styles.moreDetails} to={`singleproject${route}`}>
            <span className={styles.moreDetailsText}>Explore </span>
            &gt;&gt;
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
