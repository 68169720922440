import React from "react";
import Card from "../Card/Card";
import styles from "./Cards.module.css";

const Cards = ({ data }) => {
  return (
    <div className={styles.container}>
      {data.map((curr, i) => {
        return (
          <Card
            img={curr.carouselImages[0]}
            state={curr.type}
            route={curr.route}
            bookingsOpen={curr.bookingsOpen}
            location={curr.location.name}
            gmapsLink={curr.location.link}
            name={curr.name}
          />
        );
      })}
    </div>
  );
};

export default Cards;
