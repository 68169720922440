import React from "react";
import styles from "./Footer.module.css";
import commonstyles from "../../shared/commonstyles.module.css";
import mapIcon from "../../Images/icons/map.svg";
import facebook from "../../Images/icons/facebook.svg";
import instagram from "../../Images/icons/instagram.svg";
import phoneIcon from "../../Images/icons/phone.svg";
import jcs from "../../Images/logos/logo.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div
        className={`${styles.footercnt1} ${styles.flex} ${styles.jus} ${styles.footer1}`}
      >
        <div className={styles.top1}>
          <img src={jcs} className={styles.bottom1} />
          <p className={styles.bot1}>
            We take pride in the quality of our <br></br>construction and on
            time projects deliveries<br></br> and strive to surpass the set
            standards.
          </p>
          <div>
            {" "}
            {/*   <img className={styles.img} src={facebook} /> */}
            <a
              href="https://instagram.com/jcsbuildersand?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <img className={styles.img} src={instagram} />
            </a>
          </div>
        </div>
        <div className={styles.top}>
          <p className={styles.headings}>COMPANY</p>
          <ul className={styles.list}>
            <li className={styles.bottom1}>
              <Link to="/home">Home</Link>
            </li>
            <li className={styles.bottom1}>
             
              <Link to="/aboutus">About Us</Link>
              </li>
            <li className={styles.bottom1}>
            <Link to="/ourprojects">Projects</Link>
            </li>
            <li className={styles.bottom1}>
            <Link to="/contactus">Contact US</Link>
            </li>
          </ul>
        </div>
        <div className={styles.top}>
          <p className={styles.headings}>REAL ESTATE</p>
          <ul className={styles.list}>
            <li className={styles.bottom1}>Building</li>
            <li className={styles.bottom1}>Developing</li>
            <li className={styles.bottom1}>Joint Ventures</li>
          </ul>
        </div>
        <div className={styles.top}>
          <p className={styles.headings}>CONSTRUCTION</p>
          <ul className={styles.list}>
            <li className={styles.bottom1}>Building Contracts</li>
            <li className={styles.bottom1}>Govt Contry</li>
            <li className={styles.bottom1}>Interior Designs</li>
          </ul>
        </div>
        <div className={styles.top}>
          <p className={styles.headings}>CONTACT US</p>

          <div className={`${styles.bottom} ${styles.flex}`}>
            <img src={phoneIcon} className={styles.right} />
            <p className={styles.bot}>+91 82964 94817</p>
          </div>
          <div className={styles.flex}>
            <img src={mapIcon} className={styles.right} />
            <p className={styles.bot}>
              506, 3rd floor, 60 feet main <br></br>road, opp to Canara bank{" "}
              <br></br>Sahakarnagar Banglore-<br></br> 560092
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.footercnt2} ${styles.flex} ${styles.jus}`}>
        <p> copyright © All rights reserved 2020</p>
        <p>Privacy Policy</p>
      </div>
    </div>
  );
}

export default Footer;
