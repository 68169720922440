import React from "react";
import styles from "./OurVision.module.css";
import commonstyles from "../../shared/commonstyles.module.css";

function OurVision() {
  return (
    <div className={`${commonstyles.bgWhite} ${commonstyles.ComponentsCnt}`}>
      <div className={`${styles.secondBanner} d-flex`}>
  
        <div
          className={`pe-5 ${commonstyles.flex - 1} ${
            commonstyles.textAlignLeft
          }`}
        >
          <h2
            className={`${commonstyles.colorOrange}  ${commonstyles.textAlignLeft}`}
          >
            OUR VISION
          </h2>
          <div className={`${commonstyles.flexAlignCenter}`}>
            <h1
              className={`${commonstyles.textUpperCase} ${commonstyles.smallerfont} fw-bold ${commonstyles.colorTheme}`}
            >
              MAKE YOUR
            </h1>
            <h1
              className={`${commonstyles.textUpperCase} ${commonstyles.smallerfont} fw-bold ${commonstyles.colorTheme}`}
            >
              DREAMS
            </h1>
            <h1
              className={`${commonstyles.textUpperCase} ${commonstyles.smallerfont} fw-bold ${commonstyles.colorTheme}`}
            >
              COME TRUE
            </h1>
          </div>
        </div>
        <div
          className={`${commonstyles.flexOnehalf} d-flex justify-content-center align-items-center`}
        >
          <p className={`${commonstyles.textAlignLeft}`}>
            India's No.1 tech-enabled construction company that aims to make
            construction simple, transparent, and reliable. We provide
            end-to-end aka design-to-build construction services. We execute
            projects on a turnkey basis and provide real-time tracking,
            communication, quality checks, and updates on projects from day one
            till the handover of the keys.
          </p>
        </div>
      </div>
    

    </div>
  );
}

export default OurVision;
