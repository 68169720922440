import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import SocialMediaLink from "../../components/SocialMediaLink/SocialMediaLink";
import styles from "../HomePage/HomePage.module.css";
import homeBanner from "../../Images/banner/homebanner.png";
import commonstyles from "../../shared/commonstyles.module.css";
import { Button } from "react-bootstrap";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer1/Footer1";

function ContactusPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.title = "Contact Us JCS Builder";
  }, []);
  return (
    <>
      <ContactUs />
    </>
  );
}

export default ContactusPage;
