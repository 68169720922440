import React, { useState } from "react";
import jcsLogo from "../../Images/logos/logo.svg";
import commonstyles from "../../shared/commonstyles.module.css";
import styles from "./Header.module.css";
import { Link, withRouter } from "react-router-dom";
import "./Header.css";
import $ from "jquery";
function Header({ history }) {
  const pathName = history.location.pathname;
  const [toggle, setToggle] = useState(false);
  function toggleicon() {
    setToggle(!toggle);
    console.log("click");
  }

  function falsestate() {
    setToggle(false);
  }

  return (
    <>
      <nav
        className={`${commonstyles.container} navbar d-flex  navbar-expand-sm ${styles.bg_blue} navbar-dark`}
      >
        <Link className={`navbar-brand ${styles.paddingLeft45}`} to="/">
          <img src={jcsLogo} height="38" />{" "}
        </Link>
        <h3 className="colorWhite show-mobile-view">JCS Builders</h3>
        {/*  <div className="collapse navbar-collapse" id="navbarNav"> */}
        <i class="fa-solid fa-bars" onClick={toggleicon} id="icon"></i>

        <ul
          className={
            toggle
              ? "navbar-nav d-flex justify-content-around flex-fill"
              : "navbar-nav d-flex justify-content-around flex-fill disp"
          }
          id="header-elements"
        >
          <li className="nav-item flex-grow-1 headerwid px-5">
            <Link className={`nav-link`} to="/" onClick={falsestate}>
              Home
            </Link>
          </li>
          <li className="nav-item flex-grow-1 headerwid  px-5">
            <Link className={`nav-link`} to="/aboutus" onClick={falsestate}>
              About us
            </Link>
          </li>
          <li className="nav-item flex-grow-1 headerwid  px-5">
            <Link className={`nav-link`} to="/service" onClick={falsestate}>
              Service
            </Link>
          </li>

          <li className="nav-item flex-grow-1 headerwid px-5">
            <Link className={`nav-link`} to="/ourprojects" onClick={falsestate}>
              Projects
            </Link>
          </li>
          <li className="nav-item flex-grow-1 headerwid px-5">
            <Link className={`nav-link`} to="/contactus" onClick={falsestate}>
              Contact
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              className={`nav-link ${
                pathName.includes("singleproject") ? "active" : ""
              }`}
              to="/singleproject"
            >
              Single Project
            </Link>
          </li> */}
        </ul>
        <a className={`navbar-brand invisible ${styles.paddingRight35}`}>
          <i className="fa fa-user" aria-hidden="true"></i>
        </a>
        {/*  </div> */}
      </nav>
    </>
  );
}

export default withRouter(Header);
