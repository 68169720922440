import React, { useState } from "react";
import styles from "./Accordion.module.css";
import { faAngleDown, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Accordion = ({ accordionOptions = [], handleSelectedValue }) => {
  const [selectedOption, setSelectedOption] = useState("ALL");
  const [toggleAccordion, setToggleAccordion] = useState(false);
  const arr = accordionOptions.filter((option) => option !== selectedOption);
  return (
    <div className={styles.accordionContainer}>
      <div className={styles.accordion}>
        <div
          className={`${styles.accordionHead} ${
            toggleAccordion && styles.accordionActive
          }`}
          onClick={() => setToggleAccordion(!toggleAccordion)}
        >
          <span>{selectedOption}</span>
          <div className={styles.Icon}>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </div>
        <div className={styles.accordionOptions}>
          {toggleAccordion &&
            arr.map((option, index) => {
              return (
                <p
                  key={index}
                  className={styles.option}
                  onClick={() => {
                    setSelectedOption(option);
                    handleSelectedValue(option);
                    setToggleAccordion(false);
                  }}
                >
                  {option}
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
