import React from "react";
import styles from "./ContactUs.module.css";
import commonstyles from "../../shared/commonstyles.module.css";
import contactus from "../../Images/contactus.svg";
import facebook from "../../Images/facebook.svg";
import instagram from "../../Images/instagram.svg";
import linkedin from "../../Images/linkedin.png";
import twitter from "../../Images/twitter.svg";
import phoneIcon from "../../Images/phone.svg";
import mapIcon from "../../Images/address.svg";
import email from "../../Images/mail.svg";

function ContactUs() {
  return (
    <>
      <div className={styles.flex}>
        <h1 className={styles.color}>
          WE’D LOVE TO WORK WITH YOU<br></br>
          <span className={styles.color1}>CONTACT US</span>
        </h1>
        <img src={contactus} className={styles.imgcon} />
      </div>
      <div className={styles.contactuscont}>
        <div>
          <div className={styles.flexbox}>
            <img src={phoneIcon} className={styles.pos} />
            <div>
              <p className={styles.text}>PHONE NO.</p>
              <p>+91 82694 94817</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.flexbox}>
            <img src={email} className={styles.pos} />
            <div>
              <p className={styles.text}>EMAIL</p>
              <p>sale@jcsbuildersanddevelopers.com</p>
              <p>info@jcsbuildersanddevelopers.com</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.flexbox}>
            <img src={mapIcon} className={styles.pos} />
            <div>
              <p className={styles.text2}>ADDRESS</p>
              <p className={styles.p}>
                506/1, 3rd floor, 60 feet main road,<br></br>
                opp to Canara bank Sahakarnagar<br></br> Banglore- 560092
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className={styles.text1}>FOLLOW US</p>
          <div className={styles.left}>
            {" "}
            {/*  <img className={styles.img} src={facebook} /> */}
            <a
              href="https://www.instagram.com/jcs_buildersanddevelopers/"
              target="_blank"
            >
              {" "}
              <img className={styles.img} src={instagram} />
            </a>
            <a
              href="https://www.facebook.com/Jcsbuildersanddevelopers/"
              target="_blank"
            >
              {" "}
              <img className={styles.img} src={facebook} />
            </a>
            <a
              href="https://twitter.com/developers_jcs"
              target="_blank"
            >
              {" "}
              <img className={styles.img} src={twitter} />
            </a>
            <a
              href="http://linkedin.com/in/jcs-builders-and-developers-40684a248"
              target="_blank"
            >
              {" "}
              <img className={styles.img} src={linkedin} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
