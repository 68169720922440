import React, { useEffect, useState } from "react";
import Slider1 from "../../components/Slider1/Slider1";
import { withRouter, Link } from "react-router-dom";
import styles from "./HomePage.module.css";
import aboutsImage from "../../Images/banner/aboutUsImage.svg";

import { carouselProjectData } from "../../data/data";
import icon1 from "../../Images/icon1.JPG";
import icon2 from "../../Images/icon2.JPG";
import icon3 from "../../Images/icon3.JPG";
import icon4 from "../../Images/icon4.JPG";
import icon5 from "../../Images/icon5.JPG";
import icon6 from "../../Images/icon6.JPG";
import homeBanner from "../../Images/banner/homebanner.png";
import commonstyles from "../../shared/commonstyles.module.css";
import { Button } from "react-bootstrap";
import AboutUs from "../../components/AboutUs/AboutUs";
import RealEstateServices from "../../components/RealEstateServices/RealEstateServices";
import ReadyToMoveIn from "../../components/ReadyToMoveIn/ReadyToMoveIn";
import ProjectsBanner from "../../components/ProjectsBanner/ProjectsBanner";
import Footer from "../../components/Footer1/Footer1";
import Achievements from "../../components/Achievements/Achievements";

function HomePage({ history }) {
  const navigateToProjects = () => {
    history.push("/ourprojects");
  };
  const [DATA, setDATA] = useState({
    route: "",
    carouselImages: [],
    name: "",
    path: "",
  });
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const pathname = history.location.pathname;
    const selected = carouselProjectData.filter((a) =>
      pathname.includes(a.route)
    )[0];
    setDATA(selected);
    console.log(Object.values(DATA));
  }, []);
  return (
    <>
      <Slider1 className="opac"  data={carouselProjectData}   />

      <div className={styles.container}>
        <div className={styles.imgContainer}>
          <div className={styles.imgBlock}>
            <h1
              className={`${commonstyles.textAlignLeft}  ${commonstyles.homefont} ${commonstyles.colorTheme} ${commonstyles.font90px}`}
            >
              MOST TRUSTED{" "}
            </h1>
            <h1
              className={`${commonstyles.textAlignLeft}  ${commonstyles.homefont} ${commonstyles.colorTheme} ${commonstyles.font90px}`}
            >
              {" "}
              COMPANY IN BANGLORE
            </h1>
            <p
              className={`fw-bold ${commonstyles.textAlignLeft}  ${commonstyles.homefont} ${commonstyles.colorHeading}`}
            >
              Bookings Open
            </p>
            <div className={`${commonstyles.textAlignLeft}`}>
              {" "}
              <Button
                className={`${commonstyles.bgOrange} ${styles.bookingBtn}`}
                variant="primary"
                onClick={navigateToProjects}
              >
                Explore
              </Button>
            </div>
          </div>
          <img src={homeBanner} className={styles.homeBanner} />
        </div>
        <div
          className={`${commonstyles.bgWhite} ${commonstyles.ComponentsCnt} bg-white`}
        >
          <div className={`${styles.aboutUs2ndBanner} d-flex`}>
            <div
              className={`p-2 flex-fill ${commonstyles.textAlignLeft} ${styles.aboutUsText}`}
            >
              <h2
                className={`${commonstyles.colorOrange} ${commonstyles.textAlignLeft} ${commonstyles.fontWeight700}`}
              >
                ABOUT US
              </h2>
              <h1
                className={`${commonstyles.textUpperCase} ${commonstyles.smallerfont} fw-bold ${commonstyles.colorTheme}`}
              >
                transparency at every stage of construction
              </h1>
              <p>
                JCS builders and developers is an real estate company which is
                started in 2015 in the Bangalore north city since then, we have
                created a legacy of innovative and futuristic landmarks. We
                develop and build layouts,residential and commercial spaces{" "}
                <br></br>in Bangalore. We also do the government contracts. We
                constantly motivated by our mission to conquer new heights and
                achieve the significant milestone of success. We take pride in
                the quality of our construction and o n time projects deliveries
                and strive to surpass the set standards. We believe in listening
                to our customers and their satisfaction forms the core of our
                services.
              </p>
            </div>
            <div className="p-2 flex-fill">
              <div>
                <img className={`${styles.imgCnt}`} src={aboutsImage} />
              </div>
            </div>
          </div>
        </div>

        <RealEstateServices />
        <ReadyToMoveIn />
        <ProjectsBanner />
        <Achievements />
      </div>
    </>
  );
}

export default withRouter(HomePage);
