import React from "react";
import styles from "./Achievements.module.css";
import commonstyles from "../../shared/commonstyles.module.css";
import view from "../../Images/banner/contactusBanner.png";
import { Link } from "react-router-dom";
export default function Achievements() {
  return (
    <>
      <div className={`${commonstyles.ComponentsCnt}`}>
        <div className={`${styles.secondBanner} d-flex`}>
          <div
            className={`pe-3 ${commonstyles.textAlignLeft} ${styles.borderRight}`}
          >
            <h2 className={`${commonstyles.colorOrange}`}>OUR ACHIEVEMENTS</h2>
            <div className={`${commonstyles.flexAlignCenter}`}>
              <h1
                className={`${commonstyles.colorTheme} ${commonstyles.smallerfont} fw-bold`}
              >
                WHY YOU SHOULD
              </h1>
              <h1
                className={`${commonstyles.colorTheme}  ${commonstyles.smallerfont} fw-bold`}
              >
                CHOOSE US
              </h1>
            </div>
          </div>
          <div className="p-2 flex-fill">
            <div className={`${styles.gridContainer} mt-5`}>
              <div className="p-2">
                <Count count={3} name="PROJECTS" />
              </div>
              <div className="p-2">
                <Count count={100} name="CLIENTS" />
              </div>
              <div className="p-2">
                <Count count={15} name="YEARS OF EXPERIENCE" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${commonstyles.ComponentsCnt}`}>
        <div className={styles.flex}>
          <div>
            <h1 className={styles.connect}>CONNECT WITH US </h1>
            <h1 className={styles.for}> FOR ENQUIRIES </h1>
            <Link to="/contactus">
            <button className={styles.button}>
             Contact Us
            </button>
            </Link>
          </div>
          <img className={styles.wid} src={view} />
        </div>
      </div>
    </>
  );
}

const Count = ({ name, desc, count }) => {
  return (
    <div>
      <h3 className={`${commonstyles.colorOrange} fw-bold`}>
        {" "}
        <span className={`${commonstyles.largeFont}`}>{count}</span>+
      </h3>{" "}
      <h5 className={`${commonstyles.colorTheme} fw-bold`}>{name}</h5>
      <p className={`${styles.desc}`}>{desc}</p>
    </div>
  );
};
