import React, { useState } from "react";
import singlepage from "../../Images/singlepage.svg";
import Carousel from "react-bootstrap/Carousel";
import styles from "../Singlepage/Singlepage.module.css";
import "./Slider.css";

export default function Slider({ imagesData }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      slide={true}
      interval={500}
      className="opac"
    >
      {imagesData.map((image) => (
        <Carousel.Item>
          <img className="d-block w-100" src={image} alt="First slide" />

          {/* <div className={`d-flex justify-content-end mb-3 ${styles.book}`}>
           <button className={styles.bookbutton}>Booking Open</button>
         </div>
           <div className={`d-flex justify-content-end ${styles.book}`}>
             <button className={styles.bookbutton}>Explore</button>
           </div> */}

          {/*  <Carousel.Caption>
           <h3>Bookings Open</h3>
           <p>Explore</p>
         </Carousel.Caption> */}
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
