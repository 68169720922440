import React,{useEffect} from "react";
import Header from "../../components/Header/Header";
import Singlepage from "../../components/Singlepage/Singlepage";
import Footer from "../../components/Footer1/Footer1";
import SocialMediaLink from "../../components/SocialMediaLink/SocialMediaLink";

function SinglePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  
    
  }, [])
  
  return (
    <>
      <Singlepage />
    </>
  );
}

export default SinglePage;
