import React from "react";
import styles from "./ReadyToMoveIn.module.css";
import commonstyles from "../../shared/commonstyles.module.css";
import building1 from "../../Images/buildings/building1.svg";
import map from "../../Images/icons/mapBlack.svg";
import { Link } from "react-router-dom"

export default function ReadyToMoveInCard({item}) {
  return (
    <div
      className={`${commonstyles.bgDarkWhite} ${commonstyles.borderRadius4px}`}
    >
      <img src={item.carouselImages} className={`${styles.imgCnt}`} />
      <div className={`${styles.detailsCnt}`}>
        <h4 className="text-align-center">{item.name}</h4>
        <div className="d-flex justify-content-between">
          <div className="p-2 flex-grow d-flex flex-reverse justify-content-center">
            <img
              src={map}
              color="black"
              className={`${commonstyles.marginRight4px}`}
            />
           {item.location}
          </div>
          <div className="p-2 flex-grow">
          <Link className={commonstyles.moreDetails} to={item.path}>More Details &gt;&gt;</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
