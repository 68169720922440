import React from "react";
import styles from "./ProjectsBanner.module.css";
import {Link} from "react-router-dom";
import commonstyles from "../../shared/commonstyles.module.css";
import ProjectsCards from "./ProjectsCards";
import NGK1 from "../../Images/projects/huttanahalli/6.webp"
import NGK2 from "../../Images/projects/huttanahalli/5.webp"

export default function ProjectsBanner() {
  return (
    <div className={`${commonstyles.ComponentsCnt} ${commonstyles.bgWhite}`}>
      <div className="d-flex">
        <div className={`p-2 ${commonstyles.textAlignLeft}`}>
          <h5 className={`${commonstyles.colorOrange} fw-bold`}>
            READY TO MOVE IN
          </h5>
          <h1 className={`${commonstyles.colorTheme} fw-bold`}>
            AMAZING CONSTRUCTIONS
          </h1>
          <h1 className={`${commonstyles.colorTheme} fw-bold`}>BUILD BY US</h1>
        </div>
        <div className="p-2 flex-fill">
          <div
            className={`d-flex justify-content-center flex-column mt-5 ${commonstyles.colorOrange} ${commonstyles.borderLine}`}
          ></div>
        </div>
      </div>
      <div className={commonstyles.rightview}>
      <Link to="/ourprojects">View All &gt;</Link>
      </div>
      <div className={`d-flex ${commonstyles.dir}`}>
        <div className={`p-2`}>
          <ProjectsCards name="NGK Orchids" image={NGK1} />
        </div>
        <div className="p-2">
          <ProjectsCards name="NGK Orchids" image={NGK2} />
        </div>
      </div>
    </div>
  );
}
