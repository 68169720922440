import React from "react";
import commonstyles from "../../shared/commonstyles.module.css";
import serviceIcon from "../../Images/icons/serviceIcon.svg";
import styles from "./RealEstateServices.module.css";
import building from "../../Images/services/building.webp"

export default function RealEstateServiceCard({ name,desc,image }) {
  return (
    <div className={`${commonstyles.bgWhite} ${styles.cardCnt} `}>
      <img src={image} className={`mb-5 ${styles.imageStyle}`} />
      <div className={`${commonstyles.colorOrange} ${commonstyles.font20}`}>{name}</div>
      <p className={`${commonstyles.font300}`}>{desc}</p>
    </div>
  );
}
