import React, { useEffect, useState } from "react";
import styles from "./ServiceP.module.css";
import icon from "../../Images/icon.svg";
import building from "../../Images/services/building.webp";
import developing from "../../Images/services/developing.webp";
import jointVenture from "../../Images/services/jointVenture.webp";
import buildingContract from "../../Images/services/buildingContract.webp";
import governmentContry from "../../Images/services/governmentContry.webp";
import interiorDesigns from "../../Images/services/interiorDesigns.webp";

function ServiceP() {
  return (
    <>
      <div className={styles.flex}>
        <div>
          <h1 className={styles.flexheading}>SERVICES</h1>
          <h1 className={styles.flexhead}>
            {" "}
            RELYING ON <br></br>YOUR NEEDS
          </h1>
          <p className={styles.flexpara}>ALL KINDS OF SERVICES PROVIDED</p>
        </div>
        <hr className={styles.hr} />
        <div>
          <div>
            <h2 className={styles.align}>REAL ESTATE SERVICES</h2>
            <div className={styles.flex1}>
              <div className={styles.divs}>
                <img src={building} className={styles.imageStyle} />
                <h2 className={styles.divsp}>Building</h2>
              </div>
              <div className={styles.divs}>
                <img src={developing} className={styles.imageStyle} />
                <h2 className={styles.divsp}>Developing</h2>
              </div>
              <div className={styles.divs}>
                <img src={jointVenture} className={styles.imageStyle} />
                <h2 className={styles.divsp}>Joint Ventures</h2>
              </div>
            </div>
          </div>
          <div className={styles.marg}>
            <h2 className={styles.align}>Construction services</h2>
            <div className={styles.flex1}>
              <div className={styles.divs}>
                <img src={buildingContract} className={styles.imageStyle} />
                <h2 className={styles.divsp}>Building Contracts</h2>
              </div>
              <div className={styles.divs}>
                <img src={governmentContry}  className={styles.imageStyle}/>
                <h2 className={styles.divsp}>Govt Contry</h2>
              </div>
              <div className={styles.divs}>
                <img src={interiorDesigns} className={styles.imageStyle} />
                <h2 className={styles.divsp}>Interior Designs</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceP;
