import React from "react";
import styles from "./ProjectsBanner.module.css";

export default function ProjectsCards({name,image}) {
  return (
    <div className="position-relative">
      <img src={image} className={styles.imgcnt} />
      <div className={styles.imgText}>
        <h5>{name}</h5>
       
      </div>
    </div>
  );
}
