import huttan1 from "../Images/projects/huttanahalli/1.webp";
import huttan2 from "../Images/projects/huttanahalli/2.webp";
import huttan3 from "../Images/projects/huttanahalli/3.webp";
import huttan4 from "../Images/projects/huttanahalli/4.webp";
import huttan5 from "../Images/projects/huttanahalli/5.webp";
import huttan6 from "../Images/projects/huttanahalli/6.webp";

import kadigen1 from "../Images/projects/kadegenahalli/1.webp";
import kadigen2 from "../Images/projects/kadegenahalli/2.webp";
import kadigen3 from "../Images/projects/kadegenahalli/3.webp";
import kadigen4 from "../Images/projects/kadegenahalli/4.webp";
import kadigen5 from "../Images/projects/kadegenahalli/5.webp";

import kundana1 from "../Images/projects/kundana/1.webp";
import kundana2 from "../Images/projects/kundana/2.webp";
import kundana3 from "../Images/projects/kundana/3.webp";
import kundana4 from "../Images/projects/kundana/4.webp";

import doddaballapur1 from "../Images/projects/doddaballapur/1.webp";
import doddaballapur2 from "../Images/projects/doddaballapur/2.webp";
import doddaballapur3 from "../Images/projects/doddaballapur/3.webp";
import doddaballapur4 from "../Images/projects/doddaballapur/4.webp";
import doddaballapur5 from "../Images/projects/doddaballapur/5.webp";
import doddaballapur6 from "../Images/projects/doddaballapur/6.webp";
import doddaballapur7 from "../Images/projects/doddaballapur/7.webp";
import doddaballapur8 from "../Images/projects/doddaballapur/8.webp";
import doddaballapur9 from "../Images/projects/doddaballapur/9.webp";
import doddaballapur10 from "../Images/projects/doddaballapur/10.webp";
import doddaballapur11 from "../Images/projects/doddaballapur/video1.MOV";

export const singleProjectData = [
  {
    route: "/huttanahalli",
    carouselImages: [huttan1, huttan2, huttan3, huttan4, huttan5, huttan6],
    videos: [],
    name: " NGK Orchids",
    type: "ongoing",

    location: {
      name: "Huttanahalli",
      link: "https://www.google.com/maps/place/Huttanahalli,+Bengaluru,+Karnataka+562157/@13.1622444,77.6507246,16z/data=!3m1!4b1!4m5!3m4!1s0x3bae1ea62aae045d:0x4d17bb1022d80b53!8m2!3d13.1653704!4d77.6499525",
    },
    bookingsOpen: true,
    description: `<div>
    <div

/>
      <h1 class="description-heading">Sites and Villa Plots for Sale</h1>
      <div class="sub-part">
        <p>Price:</p>
        <p>₹ 3200 per sqft</p>
      </div>
      <div class="sub-part">
        <p>Sites:</p>
        <p>30 x 40 sites and odd sites</p>
      </div>
    </div>
`,
    pointsData: ["Luxurious & Spacious", "G+1 Villla Available"],
  },
  {
    route: "/Kadigenahalli",
    carouselImages: [kadigen1, kadigen2, kadigen3, kadigen4, kadigen5],
    videos: [],
    name: "Sri Hanuman Enclave",
    type: "ongoing",
    bookingsOpen: true,
    location: {
      name: "Kadigenahalli",
      link: "https://goo.gl/maps/U9tuUW3QHwhN32Qh8",
    },
    description: `<div>
    <div

/>
      <h1 class="description-heading">Sites and Villa Plots for Sale</h1>
      <div class="sub-part">
        <p>Price:</p>
        <p>₹ 3500 per sqft</p>
      </div>
      <div class="sub-part">
        <p>Sites:</p>
        <p>30 x 40 sties and odd sitess</p>
      </div>
      <table class="table-component-2">
      <thead>
        <tr>
          <th>Sl No.</th>
          <th>Site Dimension</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1.</td>
          <td>30' X 40</td>
          <td>09</td>
        </tr>
        <tr>
          <td>2.</td>
          <td>30' X 50'</td>
          <td>15</td>
        </tr>
        <tr>
          <td>3.</td>
          <td>40' X 50'</td>
          <td>02</td>
        </tr>
        <tr>
          <td>4.</td>
          <td>40' X 40'</td>
          <td>01</td>
        </tr>
        <tr>
          <td>5.</td>
          <td>ODD</td>
          <td>26</td>
        </tr>
        <tr>
          <td colspan="2">Total</td>

          <td>53</td>
        </tr>
      </tbody>
    </table>

    </div>`,

    pointsData: ["Luxurious & Spacious", "Sites for Sale"],
  },
  {
    route: "/Doddaballapur",
    carouselImages: [
      doddaballapur1,
      doddaballapur2,
      doddaballapur3,
      doddaballapur4,
      doddaballapur5,
      doddaballapur6,
      doddaballapur7,
      doddaballapur8,
      doddaballapur9,
      doddaballapur10,
    ],
    videos: [doddaballapur11],
    type: "completed",
    name: "Luxury House",
    bookingsOpen: false,
    location: {
      name: "Doddaballapura",
      link: "https://www.google.com/maps/place/Doddaballapura,+Karnataka/data=!4m2!3m1!1s0x3bb1de38e96323a3:0xbf906b32e5d81163?sa=X&ved=2ahUKEwiLqKHw-IL5AhV8HLcAHeZoBH0Q8gF6BAgMEAE",
    },
    description: "",
    pointsData: ["Luxurious & Spacious", "Completed Villas Available"],
  },
];
export const carouselProjectData = [
  {
    route: "/",
    // carouselImages: [huttan1, kadigen1, doddaballapur1],
    carouselImages: huttan1,
    name: "NGK Orchids",
    path: "/singleproject/huttanahalli",
    location:"Huttanahalli"
  },
  {
    route: "/",
    carouselImages: kadigen1,
    name: "Sri Hanuman Enclave",
    path: "/singleproject/Kadigenahalli",
    location:"Kadigenahalli"
  },
  {
    route: "/",
    carouselImages: doddaballapur1,
    name: "Luxury House",
    path: "/singleproject/Doddaballapur",
    location:"Doddaballapur"
  },
];
