import React, { useEffect } from "react";
import { useState } from "react";
import Header from "../../components/Header/Header";
import { singleProjectData } from "../../data/data";
import Accordion from "../Accordion/Accordion";
import AmazingConstructionsBanner from "../AmazingConstructionsBanner/AmazingConstructionsBanner";
import Cards from "../Cards/Cards";
import DreamPlotBanner from "../DreamPlotBanner/DreamPlotBanner";
import Footer from "../Footer/Footer";
import TabComponent from "../TabComponent/TabComponent";
import styles from "./OurProjects.module.css";

const OurProjects = () => {
  const [selectedValue, setSelectedValue] = useState("ALL");
  const [arr, setArr] = useState(singleProjectData);
  let data = singleProjectData;
  const [showCards, setShowCards] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  React.useEffect(() => {
    
    if (selectedValue === "ALL") {
      setArr(singleProjectData);
    }
    if (selectedValue === "COMPLETED") {
      data = singleProjectData.filter((curr) => curr.type === "completed");
      setArr(data);
    }
    if (selectedValue === "ONGOING") {
      data = singleProjectData.filter((curr) => curr.type === "ongoing");
      setArr(data);
    }
  }, [selectedValue]);
  const handleSelectedValue = (value) => {
    setSelectedValue(value);
  };

  return (
    <div>
     {/*  <DreamPlotBanner title="DREAM PLOTS" subtitle="PROJECTS" /> */}
      <AmazingConstructionsBanner />
      <div className={styles.textAlignRight}>
        <Accordion
          accordionOptions={["ALL", "COMPLETED", "ONGOING"]}
          handleSelectedValue={handleSelectedValue}
        />

        <TabComponent handleSelectedValue={handleSelectedValue} />
      </div>

      <Cards data={arr} />
    </div>
  );
};

export default OurProjects;
