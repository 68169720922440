import React from "react";
import RealEstateServiceCard from "./RealEstateServiceCard";
import commonstyles from "../../shared/commonstyles.module.css";
import styles from "./RealEstateServices.module.css";
import building from "../../Images/services/building.webp";
import developing from "../../Images/services/developing.webp";
import jointVenture from "../../Images/services/jointVenture.webp";
import buildingContract from "../../Images/services/buildingContract.webp";
import governmentContry from "../../Images/services/governmentContry.webp";
import interiorDesigns from "../../Images/services/interiorDesigns.webp";
import { Link } from "react-router-dom";

export default function RealEstateServices() {
  return (
    <div className={`d-flex ${commonstyles.ComponentsCnt} ${commonstyles.dir}`}>
      <div
        className={`p-2 flex-fill d-flex flex-column justify-content-center ${styles.borderRight} `}
      >
        <h3
          className={`${commonstyles.colorOrange} fw-bold ${commonstyles.textAlignLeft}`}
        >
          SERVICES
        </h3>
        <h1
          className={`${commonstyles.colorTheme} fw-bold ${commonstyles.textAlignLeft}`}
        >
          RELYING ON
        </h1>
        <h1
          className={`${commonstyles.colorTheme} fw-bold ${commonstyles.textAlignLeft}`}
        >
          YOUR NEEDS
        </h1>
      </div>
      <div className="p-2 flex-fill">
        <h4
          className={`${commonstyles.textAlignLeft}  ${commonstyles.colorTheme} fw-bold mx-3`}
        >
          REAL ESTATE SERVICES
        </h4>
        <div className={`d-flex mb-3 ${commonstyles.dir}`}>
          <Link className="p-2 flex-grow-1" to="/service">
            <RealEstateServiceCard name="Building" image={building} />
          </Link>
          <Link className="p-2 flex-grow-1" to="/service">
            <RealEstateServiceCard name="Developing" image={developing} />
          </Link>
          <Link className="p-2 flex-grow-1" to="/service">
            <RealEstateServiceCard name="Joint Ventures" image={jointVenture} />
          </Link>
        </div>
        <h4
          className={`${commonstyles.textAlignLeft} ${commonstyles.colorTheme} fw-bold mx-3`}
        >
          CONSTRUCTION SERVICES
        </h4>
        <div className={`d-flex mb-3 ${commonstyles.dir}`}>
          <Link className="p-2 flex-grow-1" to="/service">
            <RealEstateServiceCard
              name="Building Contracts"
              image={buildingContract}
            />
          </Link>
          <Link className="p-2 flex-grow-1" to="/service">
            <RealEstateServiceCard
              name="Government Contry"
              image={governmentContry}
            />
          </Link>
          <Link className="p-2 flex-grow-1" to="/service">
            <RealEstateServiceCard
              name="Interior Designs"
              image={interiorDesigns}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
