import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Modalpop.css";
import { SendEmail } from "../../shared/commonfunctions";

export default function Modalpop(props) {
  const initialValues = {
    Name: ``,
    phoneNum: ``,
    email2: ``,
    message: ``,
  };
  const form = useRef();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const handleSubmit1 = () => {
   
    let errors = validate(formValues)
    if (Object.keys(errors).length === 0) {
      SendEmail(form);
      props.onCloseModal();
      alert("Data Submitted Successfully");
    }
  };
  const validate = (values) => {
    const errors = {};
    const regexEmail = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const regexName = /^[A-Za-z]+$/;
    const regexPhoneNum = /^[0-9\b]+$/;

    if (!regexName.test(values.Name.trim())) {
      errors.Name = `This is not a valid Name`;
    }

    if (
      !(values.phoneNum.length === 10 && /^[0-9\b]+$/.test(values.phoneNum))
    ) {
      errors.phoneNum = `This is not a valid phone number`;
    }

    if (!regexEmail.test(values.email2.trim())) {
      errors.email2 = `This is not a valid email`;
    }
    if(values.message.trim().length === 0){
      errors.message = `This is not a valid message`;
    }
    setFormErrors(errors);

    return errors;
  };
  const changeinput = (e, name) => {
    const { value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    setFormErrors({});
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">&nbsp;</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label for="validationSuccess" class="form-label">
            Name
          </label>
          <input
            type="text"
            maxLength="100"
            className={formErrors.Name ? "form-control1" : "form-control"}
            value={formValues.Name}
            onChange={(e) => changeinput(e, `Name`)}
            required
          />
        </div>
        <div>
          {formErrors.Name && <div className="error">{formErrors.Name}</div>}
        </div>
        <div>
          <label for="validationError" class="form-label">
            Phone
          </label>
          <input
            className={formErrors.phoneNum ? "form-control1" : "form-control"}
            type="number"
            onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
            value={formValues.phoneNum}
            onChange={(e) => changeinput(e, `phoneNum`)}
            required
          />
        </div>
        <div>
          {formErrors.phoneNum && (
            <div className="error">{formErrors.phoneNum}</div>
          )}
        </div>

        <div>
          <label for="validationSuccess" class="form-label">
            Email
          </label>
          <input
            className={formErrors.email2 ? "form-control1" : "form-control"}
            type="text"
            maxLength="100"
            value={formValues.email2}
            onChange={(e) => changeinput(e, `email2`)}
            required
          />
        </div>
        <div>
          {formErrors.email2 && (
            <div className="error">{formErrors.email2}</div>
          )}
        </div>
        <div>
          <label for="validationError" class="form-label">
            Message
          </label>
          <textarea
            onChange={(e) => changeinput(e, `message`)}
            type="text"
            maxLength="500"
            class="form-control"
            required
            name="message"
            value={formValues.message}
          />
        </div>
        <div>
          {formErrors.message && (
            <div className="error">{formErrors.message}</div>
          )}
        </div>
        <form style={{ display: "none" }} ref={form}>
          <input name="name" type="text" value={formValues.Name} />
          <input name="phoneNum" type="text" value={formValues.phoneNum} />
          <input name="email" type="text" value={formValues.email2} />
          <textarea name="message" type="text" value={formValues.message} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-grid gap-2">
          <Button onClick={handleSubmit1} variant="primary" size="lg">
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
