import React from "react";
import ourProjects from "../../Images/banner/ourProjects.png";
import aboutusBanner from "../../Images/banner/aboutusBanner.svg";
import styles from "./DreamPlotBanner.module.css";
const DreamPlotBanner = ({ title, subtitle, showAboutUs = false }) => {
  return (
    <div className={styles.ourProjectsContainer}>
      <div className={styles.leftOurProjects}>
        <p className={styles.upperSubHeading}>{title}</p>
        <p className={styles.lowerHeading}>{subtitle}</p>
      </div>
      <div>
        {showAboutUs ? <img src={aboutusBanner} width={900} height="500" /> : <img src={ourProjects} />}
      </div>
    </div>
  );
};

export default DreamPlotBanner;
