import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import SocialMediaLink from "./components/SocialMediaLink/SocialMediaLink";
import HomePage from "./Pages/HomePage/HomePage";
import ContactusPage from "./Pages/ContactusPage/ContactusPage";
import SinglePage from "./Pages/SinglePage/SinglePage";
import OurProjects from "./components/OurProjects/OurProjects.jsx";
import Header from "./components/Header/Header";
import AboutUsPage from "./Pages/AboutUsPage/AboutUsPage";

import ServicePage from "./Pages/ServicePage/ServicePage";
import MainLayout from "./layout/MainLayout";

function App() {
  return (
    <div className='App'>
      <Router>
        <MainLayout>
          <Switch>
            <Route path='/' exact component={HomePage} />
            <Route path='/home' exact component={HomePage} />
            <Route path='/aboutus' exact component={AboutUsPage} />
            <Route path='/contactus' exact component={ContactusPage} />
            <Route path='/singleproject/:id' exact component={SinglePage} />
            <Route path='/ourprojects' exact component={OurProjects} />
            <Route path='/service' exact component={ServicePage} />
          </Switch>
        </MainLayout>
      </Router>
    </div>
  );
}

export default App;
