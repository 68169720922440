import React, { useState } from "react";
import styles from "./TabComponent.module.css";

const TabComponent = ({ handleSelectedValue }) => {
  const [selected, setSelected] = useState("ALL");
  const options = ["ALL", "COMPLETED", "ONGOING"];
  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabList}>
        {options.map((curr, i) => {
          return (
            <div
              className={curr === selected ? styles.selectedTab : styles.tab}
              onClick={() => {
                setSelected(curr);
                handleSelectedValue(curr);
              }}
              key={i}
            >
              {curr}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabComponent;
