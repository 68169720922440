import React from "react";
import styles from "./AmazingConstruction.module.css";

const AmazingConstructionsBanner = () => {
  return (
    <div className={styles.amazingConstructionCont}>
      <p className={styles.amazingText}>FIND YOUR DREAM PLOT IN YOUR CITY</p>
      <div className={styles.ContainerLine}>
        <p className={styles.blueColor}>OUR PROJECTS</p>
        <div className={styles.orangeLine}></div>
      </div>
    </div>
  );
};

export default AmazingConstructionsBanner;
