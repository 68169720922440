import React from "react";
import { useEffect } from "react";

import ServiceP from "../../components/ServiceP/ServiceP";


function ServicePage() {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });
  },[])
  return (
    <>
      <ServiceP />
    </>
  );
}

export default ServicePage;
