import React, { useEffect } from "react";
import AboutUs from "../../components/AboutUs/AboutUs";
import Achievements from "../../components/Achievements/Achievements";
import DreamPlotBanner from "../../components/DreamPlotBanner/DreamPlotBanner";
import Footer from "../../components/Footer1/Footer1";
import OurVision from "../../components/OurVision/OurVision";

export default function AboutUsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.title = "About Us JCS Builder";
  }, []);
  return (
    <>
      <DreamPlotBanner
        title="KNOW MORE"
        subtitle="ABOUT US"
        showAboutUs={true}
      />
      <AboutUs />
      <OurVision />
      <Achievements />
     
    </>
  );
}
