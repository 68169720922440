import React from "react";
import { Link } from "react-router-dom";
import styles from "./ReadyToMoveIn.module.css";
import commonstyles from "../../shared/commonstyles.module.css";
import ReadyToMoveInCard from "./ReadyToMoveInCard";
import {carouselProjectData} from "../../data/data"

export default function ReadyToMoveIn() {
  return (
    <div className={`${commonstyles.ComponentsCnt} ${commonstyles.bgWhite}`}>
      <div className="d-flex">
        <div className={`p-2 ${commonstyles.textAlignLeft}`}>
          <h5 className={`${commonstyles.colorOrange} fw-bold`}>
            READY TO MOVE IN
          </h5>
          <h1 className={`${commonstyles.colorTheme} fw-bold`}>
            VILLAS FOR SALE
          </h1>
          <h1 className={`${commonstyles.colorTheme} fw-bold`}>
            SITES FOR SALE
          </h1>
        </div>

        <div className="p-2 flex-fill">
          <div
            className={`d-flex justify-content-center flex-column mt-5 ${commonstyles.colorOrange} ${styles.borderLine}`}
          ></div>
        </div>
      </div>
      <div className={commonstyles.rightview}>
        <Link to="/ourprojects">View All &gt;</Link>
      </div>

      <div className={`d-flex ${commonstyles.dir}`}>
        {carouselProjectData.map((item,i)=> <div key={item.name} className="p-2">
          {" "}
          <ReadyToMoveInCard item={item}/>
        </div>)}
       
        {/* <div className="p-2">
          {" "}
          <ReadyToMoveInCard />
        </div>
        <div className="p-2">
          {" "}
          <ReadyToMoveInCard />
        </div> */}
      </div>
    </div>
  );
}
