import React from "react";
import styles from "./About.module.css";
import commonstyles from "../../shared/commonstyles.module.css";
import aboutsImage from "../../Images/banner/aboutUsImage.svg";
import icon1 from "../../Images/icon1.JPG";
import icon2 from "../../Images/icon2.JPG";
import icon3 from "../../Images/icon3.JPG";
import icon4 from "../../Images/icon4.JPG";
import icon5 from "../../Images/icon5.JPG";
import icon6 from "../../Images/icon6.JPG";
function AboutUs() {
  return (
    <>
    <div
      className={`${commonstyles.bgWhite} ${commonstyles.ComponentsCnt} bg-white`}
    >
      <div className={`${styles.aboutUs2ndBanner} d-flex`}>
        <div
          className={`p-2 flex-fill ${commonstyles.textAlignLeft} ${styles.aboutUsText}`}
        >
          <h2
            className={`${commonstyles.colorOrange} ${commonstyles.textAlignLeft} ${commonstyles.fontWeight700}`}
          >
            ABOUT US
          </h2>
          <h1
            className={`${commonstyles.textUpperCase} ${commonstyles.smallerfont} fw-bold ${commonstyles.colorTheme}`}
          >
            transparency at every stage of construction
          </h1>
          <p>
            JCS builders and developers is an real estate company which is
            started in 2015 in the Bangalore north city since then, we have
            created a legacy of innovative and futuristic landmarks. We develop
            and build layouts,residential and commercial spaces <br></br>in Bangalore. We
            also do the government contracts. We constantly motivated by our
            mission to conquer new heights and achieve the significant milestone
            of success. We take pride in the quality of our construction and o n
            time projects deliveries and strive to surpass the set standards. We
            believe in listening to our customers and their satisfaction forms
            the core of our services.
          </p>
        </div>
        <div className="p-2 flex-fill">
          <div>
            <img className={`${styles.imgCnt}`} src={aboutsImage} />
          </div>
        </div>
      </div>
    </div>
    <section class="about-us style-two">
        <div className={styles.mission}>
      
            <div class="row">    
                <div class="info-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column">
                    <div className={styles.title}>
                          <hr className={styles.hr}/>
                          <hr className={styles.hrow}/>
                          <div>
                          <span className={styles.color} >FIND A PLACE TO BUILD YOUR HOUSE IN YOUR CITY</span>
                            <h2 className={styles.color1}  >WHO ARE WE?</h2>
                            </div>
                        </div>
                            
                            
                       

                        <div className={styles.text}><strong>JCS Builders and Developers </strong> 
                       Established in 2015, has for long been a prominent name in the list of leading real estate organisations in Bengaluru. 
                       JCS Builders and Developers have completed over <strong>3</strong>  developed projects covering more than <strong>470+</strong> sold plots across residential spaces.<br></br>
                       At JCS Builders and Developers we relentlessly thrive to achieve and reinvent goals to design architectural masterpieces. With over 8 years of developing <strong>state-of-the-art</strong> housing, our property development portfolio is diverse in scope and style.<br></br>
                     <strong> Our emphasis is always maintained on keeping up to commitments by delivering incredible living spaces that cater to the ever-changing lifestyle.</strong></div>
 
                    </div>
                </div>

               
                <div class="info-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column">
                        <div className={styles.title}>
                          <hr className={styles.hr}/>
                          <hr className={styles.hrow}/>
                            <h2 className={styles.headings} >VISION</h2>
                        </div>

                        <h5 className={styles.text}>
                         "To be world class company in developing ideal investment opportunities, innovative real estate solutions &amp; high returns to our customer by providing premium realestate that exceeds our customers expectations."</h5>
                         <div className={styles.title}>
                          <hr className={styles.hr}/>
                          <hr className={styles.hrow}/>
                            <h2 className={styles.headings} >MISSION</h2>
                        </div>
                        <h5 className={styles.text}>
                          We are unified in consistently living out our core values and performing our professions in direct alignment with our mission statement.
                     
                      We are dedicated to providing world-class service and market-leading expertise to our clients. We are passionate about providing the extra value that others simply will not. Fanatical integrity and consistently impressive results will be the signature of our service. Our collaborative spirit and desire to grow will help us achieve the goals in time. We will make the families we serve feel special. We will succeed because we will not let our clients fail.”</h5>

                    </div>
                </div>
            </div>  
        </div>
      
    </section>
    <section class="why-choose-us" id="why-us">
        <div className={styles.mission}>
        <div className={styles.title}>
                          <hr className={styles.hr}/>
                          <hr className={styles.hrow}/>
                          <div>
                          <span className={styles.color} >FIND A PLACE TO BUILD YOUR HOUSE IN YOUR CITY</span>
                            <h2 className={styles.color1}  >WHY CHOOSE US</h2>
                            </div>
                        </div>
          

            <div  class="row padding">
               
               <div class="feature-block-two col-lg-4 col-md-6 col-sm-12 botservice">
                   <a href="#">  <div className={styles.innerbox}>
                        <div class="icon-box"><img src={icon1}/></div>
                        <div><h4>Intergerity </h4>
                        <div class="text">We conduct ourselves in the highest ethical standards,demonstrating honesty and fairness in every decision and action.</div>
                    </div>
                    </div></a>
                </div>

               
                <div class="feature-block-two col-lg-4 col-md-6 col-sm-12 botservice">
                  
                   <a href="#">  <div className={styles.innerbox}>
                        <div class="icon-box"><img src={icon2}/></div>
                        <div><h4>Accountability</h4>
                        <div class="text">We operate with a high level of accountability, taking full ownership in delivering on our commitment in time.</div>
                        </div>
                    </div></a>
                      
                </div>
                <div class="feature-block-two col-lg-4 col-md-6 col-sm-12 botservice">
                  
                  <a href="#">  <div className={styles.innerbox}>
                       <div class="icon-box"><img src={icon3}/></div>
                       <div> <h4>Knowledgeable</h4>
                        <div class="text">We strive to understand our markets and our clients’ needs.</div></div>
                   </div></a>
                     
               </div>

               <div class="feature-block-two col-lg-4 col-md-6 col-sm-12 botservice">
                  
                  <a href="#">  <div className={styles.innerbox}>
                       <div class="icon-box"><img src={icon4}/></div>
                       <div> <h4>Passionate </h4>
                        <div class="text">We believe that working with “all heart” can change the world.</div></div>
                   </div></a>
                     
               </div>

               <div class="feature-block-two col-lg-4 col-md-6 col-sm-12 botservice">
                  
                  <a href="#">  <div className={styles.innerbox}>
                       <div class="icon-box"><img src={icon5}/></div>
                       <div> <h4>Playful</h4>
                        <div class="text">We love what we do and it shows.</div></div>
                   </div></a>
                     
               </div>
               <div class="feature-block-two col-lg-4 col-md-6 col-sm-12 botservice">
                  
                  <a href="#">  <div className={styles.innerbox}>
                       <div class="icon-box"><img src={icon6}/></div>
                       <div> <h4>Upstanding</h4>
                        <div class="text">Our clients’ needs and best interests are at the heart of everything we do.</div></div>
                   </div></a>
                     
               </div>

                

                
            

              
              
               
           
            </div>
        </div>
    </section></>
  );
}

export default AboutUs;
