import React, { useState } from "react";
import singlepage from "../../Images/singlepage.svg";
import Carousel from "react-bootstrap/Carousel";
import styles from "../Singlepage/Singlepage.module.css";
import "./Slider1.css";
import { Link } from "react-router-dom";

export default function Slider1({ imagesData, data }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      slide={true}
      interval={500}
      className="opac"
    >
      {data.map((item, i) => (
        <Carousel.Item>
          <img
            className="d-block w-100 image"
            src={item.carouselImages}
            alt="First slide"
          />
          <div className="loc1">
            <div className="content">
              <div class="inner-box">
                <div class="title-box">
                  <h1>JCS Builders</h1>
                </div>
               
                <div className="price">
                <h4>{item.name}</h4>
                  Bookings Open<span></span>
                </div>
                <div class="btn-box">
                  <Link to={item.path} className="Link">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
