import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styles from "./Singlepage.module.css";
import { singleProjectData } from "../../data/data";
import commonstyles from "../../shared/commonstyles.module.css";
import singlepage from "../../Images/singlepage.svg";
import address from "../../Images/address.svg";
import single from "../../Images/single.svg";
import line from "../../Images/line.svg";
import Modalpop from "../Modalpop/Modalpop";
import phoneIcon from "../../Images/phoneIcon.svg";
import mapIcon from "../../Images/mailIcon.svg";
import Slider from "../Slider/Slider";
import { hasSelectionSupport } from "@testing-library/user-event/dist/utils";
import "./SinglePageDescription.css";
import { Popover } from "react-bootstrap";
import Modal2 from "../Modal2/Modal2";

function Singlepage({ history }) {
  const [pop, setpop] = useState(false);
  const [videoPopup, setVideoPopup] = useState(false);
  const [imagePopup, setimagePopup] = useState(false);
  const [imagePop, setImagePop] = useState(null);
  function popup() {
    setpop(true);
    console.log("hi");
  }
  const [DATA, setDATA] = useState({
    route: "",
    carouselImages: [],
    location: {
      name: "",
      link: "",
    },
    description: "",
    pointsData: [],
  });

  const onCloseModal = () => {
    setpop(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const pathname = history.location.pathname;
    const selected = singleProjectData.filter((a) =>
      pathname.includes(a.route)
    )[0];
    setDATA(selected);
  }, []);
  return (
    <>
      {imagePopup && (
        <Modal2 show={imagePopup} onHide={() => setimagePopup(false)}>
          <img
            className="video-player"
            src={imagePop}
            onClick={() => setimagePopup(true)}
          />
        </Modal2>
      )}
      <Slider className="opac" imagesData={DATA.carouselImages} />
      <p className="loc invisible">{DATA.name}</p>

      <div className={`${styles.container} ${styles.flex} ${styles.spe}`}>
        <div className={`${styles.cnt1} ${styles.border} ${styles.leftCnt}`}>
          <p className={styles.text1}>READY TO MOVE IN!!</p>
          <p className={styles.text}>{DATA.name}</p>
          <div className={styles.flex}>
            <img src={address} className={styles.icon} />
            <p className={styles.location}>{DATA.location.name}</p>
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: DATA.description }} />
          </div>
        </div>

        <div className={styles.cnt2}>
          <div className={`${styles.flex} flex-wrap gap-3 ${styles.video}`}>
            {DATA?.videos?.map((video) => (
              <div className={styles.im}>
                <video
                  className={styles.im}
                  src={video}
                  onClick={() => setVideoPopup(true)}
                />
                <i class="fa-solid fa-play play"></i>
                <Modal2 show={videoPopup} onHide={() => setVideoPopup(false)}>
                  <video
                    className="video-player"
                    src={video}
                    controls={true}
                    autoPlay={true}
                    onClick={() => setVideoPopup(true)}
                    muted={true}
                  />
                </Modal2>
              </div>
            ))}
            {DATA.carouselImages.map((image) => {
              console.log(image);
              return (
                <>
                  <img
                    className={styles.im}
                    src={image}
                    onClick={() => {
                      setImagePop(image);
                      setimagePopup(true);
                    }}
                  />
                </>
              );
            })}
          </div>
          <i class="fa-solid fa-angle-down angle"></i>
          {DATA.pointsData.map((point) => (
            <div className={`${styles.flex} ${styles.pad}`}>
              <img src={line} className={styles.icon} />
              <p>{point}</p>
            </div>
          ))}
          {DATA.type == "completed" ? (
            ``
          ) : (
            <button
              className={`${styles.button} ${styles.mar}`}
              onClick={popup}
            >
              ENQUIRE
            </button>
          )}
          <div className={styles.contactdiv}>
            <div className={styles.contact1}>
              <span class="fa fa-phone phone"></span>
              <a href="tel:+91 8296494817">
                <span className={styles.ocol}>+91 8296494817</span>
              </a>
            </div>
            <div className={styles.contact1}>
              <span class="fa fa-envelope envelope"></span>
              <a href="mailto:info@jcsbuildersanddevelopers.com">
                <span className={styles.ocol}>
                  info@jcsbuildersanddevelopers.com
                </span>
              </a>
            </div>
          </div>
          {pop ? (
            <Modalpop
              onCloseModal={onCloseModal}
              show={pop}
              onHide={() => setpop(false)}
            />
          ) : (
            ``
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(Singlepage);
