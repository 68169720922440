import React from "react";
import styles from "./Footer1.module.css";
import jcsLogo from "../../Images/logos/logo.svg";
import map from "../../Images/logos/map.jpg";

function Footer1() {
  return (
    <footer className={styles.footer}>
      <meta itemprop="name" content="JCS Developers and Builders" />
      <meta itemprop="name" content="JCS Developers and Builders" />
      <div className={styles.container}>
        <div className={styles.grid1}>
          <meta itemprop="image" content="/media/8-logo-svg-200-200-2.svg" />
          <div className={styles.div}>
            <h4 className={styles.top}>
              <span className={styles.span1}>
                <a href="/">
                  <img className={styles.img} src={jcsLogo} alt="logo" />
                </a>
                <span className={`${styles.txt} ${styles.wid}`}>
                  JCS Developers and <br></br>Builders{" "}
                </span>
              </span>
            </h4>
            <div class="description">
              <p>Building dreams</p>{" "}
            </div>
          </div>
          <div className={styles.div}>
            <h4 className={styles.top}>
              <span className={styles.spans}>&nbsp; </span>
            </h4>
            <ul className={styles.ul}>
              <li className={styles.contact4}>
                <a
                  className={styles.a}
                  href="https://jcsbuildersanddevelopers.com/"
                  title="https://jcsbuildersanddevelopers.com/"
                >
                  HOME{" "}
                </a>
                <p className={styles.p}>&gt;</p>
              </li>
              <li className={styles.contact4}>
                <a
                  className={styles.a}
                  href="/cms/4-about-us.html"
                  title="/cms/4-about-us.html"
                >
                  ABOUT US{" "}
                </a>
                <p className={styles.p}>&gt;</p>
              </li>
              <li className={styles.contact4}>
                <a className={styles.a} href="*" title="*">
                  PROJECTS{" "}
                </a>
                <p className={styles.p}>&gt;</p>
              </li>
              <li className={styles.contact4}>
                <a
                  className={styles.a}
                  href="/cms/7-contact-us.html#"
                  title="/cms/7-contact-us.html#"
                >
                  CONTACT US{" "}
                </a>
                <p className={styles.p}>&gt;</p>
              </li>
            </ul>
          </div>
          <div className={styles.div}>
            <h4 className={styles.top}>
              <span className={styles.span}>Opening hours </span>
            </h4>
            <ul className={styles.ul}>
              <li className={styles.contact2}>
                <span class="day_short">Mon</span>
                <span>09:00 - 18:00</span>
                <meta itemprop="openingHours" content="Mon 09:00-18:00" />
              </li>
              <li className={styles.contact2}>
                <span class="day_short">Tue</span>
                <span>09:00 - 18:00</span>
                <meta itemprop="openingHours" content="Tue 09:00-18:00" />
              </li>
              <li className={styles.contact2}>
                <span class="day_short">Wed</span>
                <span>09:00 - 18:00</span>
                <meta itemprop="openingHours" content="Wed 09:00-18:00" />
              </li>
              <li className={styles.contact2}>
                <span class="day_short">Thu</span>
                <span>09:00 - 18:00</span>
                <meta itemprop="openingHours" content="Thu 09:00-18:00" />
              </li>
              <li className={styles.contact2}>
                <span class="day_short">Fri</span>
                <span>09:00 - 18:00</span>
                <meta itemprop="openingHours" content="Fri 09:00-18:00" />
              </li>
              <li className={styles.contact2}>
                <span class="day_short">Sat</span>
                <span>09:00 - 18:00</span>
                <meta itemprop="openingHours" content="Sat 09:00-18:00" />
              </li>
              <li className={styles.contact2}>
                <span class="day_short">Sun</span>
                <span>09:00 - 18:00</span>
                <meta itemprop="openingHours" content="Sun 09:00-18:00" />
              </li>
            </ul>
          </div>
          <div className={styles.div}>
            <h4 className={styles.top}>
              <span className={styles.span}>Contact </span>
            </h4>
            <img className={styles.map} src={map} alt="logo" />
            <div className={styles.contact}>
              <div className={styles.contact1}>JCS Developers and Builders</div>
              <div className={styles.contact1}>
                #506/1, 3rd floor, 60 feet main road, opp to
              </div>
              <div className={styles.contact1}>canara bank, sahakarnagar .</div>
              <div className={styles.contact1}>560092 Bangalore</div>
              <div className={styles.contact1}>India </div>

              <div  className={styles.contact1}>
                <span class="fa fa-phone"></span>
                <a href="tel:+91 8296494817">
                  <span className={styles.ocol}>+91 8296494817</span>
                </a>
              </div>
              <div className={styles.contact1}>
                <span class="fa fa-envelope"></span>
                <a href="mailto:info@jcsbuildersanddevelopers.com">
                  <span className={styles.ocol}>
                    info@jcsbuildersanddevelopers.com
                  </span>
                </a>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      <div className={styles.copyright}>
        <div className={styles.container}>
          <div className={styles.grid}>
            <div class="customer">
              <p>2022 © JCS Developers and Builders </p>
            </div>
            <div class="MAIA">
              <p>
                powered by
                <a
                  className={styles.a}
                  href="https://www.tyrios.io"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  TYRIOS<sup>®</sup>{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer1;
